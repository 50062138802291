import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import AppContext from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import axios from 'axios';

const LockScreenForm = ({ type, ...rest }) => {
  // State
  const [password, setPassword] = useState('');
  const {
    userData: {
      userName,
      userEmail,
      remember,
    },
  } = useContext(AppContext);

  const { login } = useAuth();

  const { t } = useTranslation();

  const navigate = useNavigate();

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    // setRedirect(true);

    axios.post(process.env.REACT_APP_SERVER + 'login/login', {
      username: userName,
      password: password
    },
    {
      headers: {"Access-Control-Allow-Origin": "*"}
    })
    .then(function (response) {
      console.log(response);

      login({
        userId: 1,
        userName: userName,
        userEmail: userEmail,
        remember: remember,
      });
      toast.success(t('LOGIN.LOGGED_AS', {username: userName}), {
        theme: 'colored'
      });
      navigate('/');
    })
    .catch(function (error) {
      console.log(error);
      toast.error(t('LOGIN.INVALID_LOGIN'), {
        theme: 'colored'
      });
    });
  };

  return (
    <Row
      {...rest}
      as={Form}
      className={classNames('gx-2 mt-4', {
        'mx-sm-4 mb-2': type === 'simple'
      })}
      onSubmit={handleSubmit}
    >
      <Col>
        <Form.Control
          placeholder={t('LOGIN.PASSWORD')}
          value={password}
          name="password"
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </Col>
      <Col xs={type === 'simple' ? 'auto' : 4}>
        <Button
          variant="primary"
          type="submit"
          disabled={!password}
          className={classNames({ 'w-100': type !== 'simple' })}
        >
          {t('LOGIN.LOGIN')}
        </Button>
      </Col>
    </Row>
  );
};

LockScreenForm.propTypes = {
  type: PropTypes.oneOf(['simple', 'split', 'card'])
};

export default LockScreenForm;
