import React, { useContext } from 'react';
import Avatar from 'components/common/Avatar';
import LockScreenForm from 'components/authentication/LockScreenForm';
import team1 from 'assets/img/team/1.jpg';
import AuthCardLayout from 'layouts/AuthCardLayout';
import Flex from 'components/common/Flex';
import { Row, Col } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import AppContext from 'context/Context';

const LockScreen = () => {
  
  const {
    userData: {
      userName
    },
  } = useContext(AppContext);

  
  const { t } = useTranslation();


  return (
    <AuthCardLayout>
      <Row className="justify-content-center">
        <Col xs="auto">
          <Flex alignItems="center">
            <Avatar src={team1} size="4xl" className="me-4" />
            <div>
              <h4>{t('LOGIN.HELLO_USERNAME', {name: userName})}</h4>
              <p className="mb-0">
                <Trans i18nKey="LOGIN.ENTER_PASSWORD">
                  <br />
                </Trans>
              </p>
            </div>
          </Flex>
          <LockScreenForm className="mt-4" />
        </Col>
      </Row>
    </AuthCardLayout>
  );
};

export default LockScreen;
