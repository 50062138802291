import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SocialAuthButtons from './SocialAuthButtons';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'context/AuthContext';
import axios from 'axios';

const LoginForm = ({ hasLabel }) => {

  const { t } = useTranslation();
  
  const navigate = useNavigate();

  const { login } = useAuth(); 
  // State
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    remember: false
  });

  const [ searchParams ] = useSearchParams();

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    axios.post(process.env.REACT_APP_SERVER + 'login/login', {
      username: formData.username,
      password: formData.password
    },
    {
      headers: {"Access-Control-Allow-Origin": "*"}
    })
    .then(function (response) {
      console.log(response);

      login({
        userId: 1,
        userName: formData.username,
        userEmail: formData.email,
        remember: formData.remember,
        integrations: response.data.integrations
      });
      toast.success(t('LOGIN.LOGGED_AS', {username: formData.username}), {
        theme: 'colored'
      });
      const next = searchParams.get('next');
      if(next != undefined && next != ''){
        navigate(next);
      } else {
        navigate('/');
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(t('LOGIN.INVALID_LOGIN'), {
        theme: 'colored'
      });
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t('LOGIN.USERNAME')}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? t('LOGIN.USERNAME') : ''}
          value={formData.username}
          name="username"
          onChange={handleFieldChange}
          type="username"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t('LOGIN.PASSWORD')}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? t('LOGIN.PASSWORD') : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              {t('LOGIN.REMEMBER_ME')}
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/login/forgot-password`}
          >
            {t('LOGIN.FORGOT_PASSWORD')}
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.username || !formData.password}
        >
          {t('LOGIN.LOGIN')}
        </Button>
      </Form.Group>

      <Divider className="mt-4">{t('LOGIN.OR_LOGIN_WITH')}</Divider>

      <SocialAuthButtons />
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
