import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import IconButton from 'components/common/IconButton';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'context/AuthContext';
import axios from 'axios';

const CustomDateInput = forwardRef(
  (
    { value, onClick, isInvalid, isValid, formControlProps, errorMessage },
    ref
  ) => {
    return (
      <>
        <Form.Control
          ref={ref}
          isInvalid={isInvalid}
          isValid={isValid}
          value={value}
          onClick={onClick}
          {...formControlProps}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </>
    );
  }
);

const WizardInput = ({
  label,
  name,
  errors,
  type = 'text',
  options = [],
  placeholder,
  formControlProps,
  formGroupProps,
  setValue,
  datepickerProps
}) => {
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const { refresh, hasIntegration } = useAuth();
  const { t } = useTranslation();

  const startIntegration = (name) => {
    switch(name){
      case 'MERCADO_LIBRE':
        setLoading(true);
        axios.get(process.env.REACT_APP_SERVER + 'api/integrations/mlapi/getAuthorizationURL',
        {
          headers: {"Access-Control-Allow-Origin": "*"}
        })
        .then((response) => {
          setLoading(false);
          window.open(response.data.url, '', 'width=930,height=850');
          setInterval(() => {
            axios.get(process.env.REACT_APP_SERVER + 'login/refresh',
            {
              headers: {"Access-Control-Allow-Origin": "*"}
            })
            .then((response) => {
              refresh(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);

          toast.error(t('LOGIN.INVALID_LOGIN'), {
            theme: 'colored'
          });
        });
    }
  }

  if (type === 'date') {
    return (
      <Form.Group {...formGroupProps}>
        {!!label && <Form.Label>{label}</Form.Label>}

        <DatePicker
          selected={date}
          onChange={date => {
            setDate(date);
            setValue(name, date);
          }}
          customInput={
            <CustomDateInput
              formControlProps={formControlProps}
              errorMessage={errors[name]?.message}
              isInvalid={errors[name]}
              isValid={Object.keys(errors).length > 0 && !errors[name]}
            />
          }
          {...datepickerProps}
        />
      </Form.Group>
    );
  }
  

  if (type === 'mlintegration') {
    return (
      <Form.Group {...formGroupProps}>
        {!!label && <Form.Label>{label}</Form.Label>}



        <IconButton
          disabled={hasIntegration(name) || loading}
          variant={hasIntegration(name)? "success":"primary" }
          className="ms-auto px-5"
          icon={hasIntegration(name)? 'check':''}
          iconAlign="right"
          transform="down-1 shrink-4"
          onClick={() => {
            startIntegration(name);
          }}
        >
          {label}
        </IconButton>

        <DatePicker
          selected={date}
          onChange={date => {
            setDate(date);
            setValue(name, date);
          }}
          customInput={
            <CustomDateInput
              formControlProps={formControlProps}
              errorMessage={errors[name]?.message}
              isInvalid={errors[name]}
              isValid={Object.keys(errors).length > 0 && !errors[name]}
            />
          }
          {...datepickerProps}
        />
      </Form.Group>
    );
  }

  if (type === 'checkbox' || type === 'switch' || type === 'radio') {
    return (
      <Form.Check type={type} id={name + Math.floor(Math.random() * 100)}>
        <Form.Check.Input
          type={type}
          {...formControlProps}
          isInvalid={errors[name]}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
        />
        <Form.Check.Label className="ms-2">{label}</Form.Check.Label>
        <Form.Control.Feedback type="invalid" className="mt-0">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Check>
    );
  }
  if (type === 'select') {
    return (
      <Form.Group {...formGroupProps}>
        <Form.Label>{label}</Form.Label>
        <Form.Select
          type={type}
          {...formControlProps}
          isInvalid={errors[name]}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
        >
          <option value="">{placeholder}</option>
          {options.map(option => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
  if (type === 'textarea') {
    return (
      <Form.Group {...formGroupProps}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="textarea"
          placeholder={placeholder}
          {...formControlProps}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
          isInvalid={errors[name]}
          rows={4}
        />
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
  return (
    <Form.Group {...formGroupProps}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type={type}
        placeholder={placeholder}
        {...formControlProps}
        isInvalid={errors[name]}
        isValid={Object.keys(errors).length > 0 && !errors[name]}
      />
      <Form.Control.Feedback type="invalid">
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

CustomDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  formControlProps: PropTypes.object,
  errorMessage: PropTypes.string
};

WizardInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  type: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  setValue: PropTypes.func,
  datepickerProps: PropTypes.object
};

WizardInput.defaultProps = { required: false };

export default WizardInput;
