import React from 'react';
import { Link } from 'react-router-dom';
import AuthCardLayout from 'layouts/AuthCardLayout';
import RegistrationForm from './components/RegistrationForm';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Registration = () => {
  const { t } = useTranslation();
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="pt-3 text-white">
          {t('LOGIN.ASK_HAVE_ACCOUNT')}
          <br />
          <Button
            as={Link}
            variant="outline-light"
            className="mt-2 px-4"
            to="/login"
          >
            {t('LOGIN.LOGIN')}
          </Button>
        </p>
      }
      footer={false}
    >
      <h3>{t('LOGIN.REGISTER')}</h3>
      <RegistrationForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Registration;
