import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './components/LoginForm';

import AuthCardLayout from 'layouts/AuthCardLayout';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          {t('LOGIN.ASK_DONT_HAVE_ACCOUNT')}
          <br />
          <Link
            className="text-white text-decoration-underline"
            to="/login/register"
          >
            {t('LOGIN.GET_STARTED')}
          </Link>
        </p>
      }
    >
      <h3>{t('LOGIN.LOGIN')}</h3>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
