// AuthContext.js
import React, { createContext, useContext } from 'react';
import AppContext from './Context';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const {
    userData,
    setUserData,
    userDispatch,
  } = useContext(AppContext);

  const login = (user) => {
    setUserData('userId', user.userId);
    setUserData('userName', user.userName);
    setUserData('userEmail', user.userEmail);
    setUserData('remember', user.remember);
    setUserData('integrations', JSON.stringify(user.integrations));
    userDispatch({ type: 'REFRESH' });
  };

  const refresh = (user) => {
    if(user.userId != undefined) setUserData('userId', user.userId);
    if(user.userName != undefined) setUserData('userName', user.userName);
    if(user.userEmail != undefined) setUserData('userEmail', user.userEmail);
    if(user.remember != undefined) setUserData('remember', user.remember);
    if(user.integrations != undefined) setUserData('integrations', JSON.stringify(user.integrations));
    userDispatch({ type: 'REFRESH' });
  };

  const lock = () => {
    // Clear the authentication token and user data
    // Remove the token from local storage or cookies
    // Update the user state to null
    setUserData('userId', -1);
  };

  const logout = () => {
    // Clear the authentication token and user data
    // Remove the token from local storage or cookies
    // Update the user state to null
    setUserData('userId', -1);
    setUserData('userName', "");
    setUserData('userEmail', "");
    setUserData('remember', false);
    setUserData('integrations', '{}');
  };

  //Has the ID cleared but the email and name remains, so it can login again with the password
  const isLocked = () => {
    return (userData != null && userData != undefined) &&
    (userData.userId == undefined || userData.userId == -1 ) &&
    (userData.userName != undefined && userData.userName != "" ) &&
    (userData.userEmail != undefined && userData.userEmail != "" )
  }

  //Has the ID set
  const isAuthenticated = () => {
    return (userData != null && userData != undefined && userData.userId != undefined && userData.userId != -1)
  }

  //Has the ID set
  const hasIntegration = (integration) => {
    return isAuthenticated() && (
      userData.integrations != undefined && userData.integrations != null &&
      userData.integrations[integration] != undefined && userData.integrations[integration] != null &&
      userData.integrations[integration]
    )
  }

  return (
    <AuthContext.Provider value={{ userData, login, refresh, lock, logout, isLocked, isAuthenticated, hasIntegration }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}