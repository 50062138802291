import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const GuestRoute = ({ children, isAuthenticated }) => {
  return !isAuthenticated 
    ? children
    : <Navigate to='/' replace />;
};
GuestRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthenticated: PropTypes.bool
};

export default GuestRoute;