import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthSimpleLayout from '../layouts/AuthSimpleLayout';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import Landing from 'components/pages/landing/Landing';

//#region Template
import Accordion from 'components/doc-components/Accordion';
import Alerts from 'components/doc-components/Alerts';
import Badges from 'components/doc-components/Badges';
import Breadcrumbs from 'components/doc-components/Breadcrumb';
import Buttons from 'components/doc-components/Buttons';
import CalendarExample from 'components/doc-components/CalendarExample';
import Cards from 'components/doc-components/Cards';
import Dropdowns from 'components/doc-components/Dropdowns';
import ListGroups from 'components/doc-components/ListGroups';
import Modals from 'components/doc-components/Modals';
import Offcanvas from 'components/doc-components/Offcanvas';
import Pagination from 'components/doc-components/Pagination';
import BasicProgressBar from 'components/doc-components/ProgressBar';
import Spinners from 'components/doc-components/Spinners';
import Toasts from 'components/doc-components/Toasts';
import Avatar from 'components/doc-components/Avatar';
import Image from 'components/doc-components/Image';
import Tooltips from 'components/doc-components/Tooltips';
import Popovers from 'components/doc-components/Popovers';
import Figures from 'components/doc-components/Figures';
import Hoverbox from 'components/doc-components/Hoverbox';
import Tables from 'components/doc-components/Tables';
import FormControl from 'components/doc-components/FormControl';
import InputGroup from 'components/doc-components/InputGroup';
import Select from 'components/doc-components/Select';
import Checks from 'components/doc-components/Checks';
import Range from 'components/doc-components/Range';
import FormLayout from 'components/doc-components/FormLayout';
import FloatingLabels from 'components/doc-components/FloatingLabels';
import FormValidation from 'components/doc-components/FormValidation';
import BootstrapCarousel from 'components/doc-components/BootstrapCarousel';
import SlickCarousel from 'components/doc-components/SlickCarousel';
import Navs from 'components/doc-components/Navs';
import Navbars from 'components/doc-components/Navbars';
import Tabs from 'components/doc-components/Tabs';
import Collapse from 'components/doc-components/Collapse';
import CountUp from 'components/doc-components/CountUp';
import Embed from 'components/doc-components/Embed';
import Background from 'components/doc-components/Backgrounds';
import Search from 'components/doc-components/Search';
import VerticalNavbar from 'components/doc-components/VerticalNavbar';
import NavBarTop from 'components/doc-components/NavBarTop';
import NavbarDoubleTop from 'components/doc-components/NavbarDoubleTop';
import ComboNavbar from 'components/doc-components/ComboNavbar';
import TypedText from 'components/doc-components/TypedText';
import FileUploader from 'components/doc-components/FileUploader';
import Borders from 'components/utilities/Borders';
import Colors from 'components/utilities/Colors';
import ColoredLinks from 'components/utilities/ColoredLinks';
import Display from 'components/utilities/Display';
import Visibility from 'components/utilities/Visibility';
import StretchedLink from 'components/utilities/StretchedLink';
import Float from 'components/utilities/Float';
import Position from 'components/utilities/Position';
import Spacing from 'components/utilities/Spacing';
import Sizing from 'components/utilities/Sizing';
import TextTruncation from 'components/utilities/TextTruncation';
import Typography from 'components/utilities/Typography';
import VerticalAlign from 'components/utilities/VerticalAlign';
import Flex from 'components/utilities/Flex';
import Grid from 'components/utilities/Grid';
import WizardForms from 'components/doc-components/WizardForms';
import GettingStarted from 'components/documentation/GettingStarted';
import Configuration from 'components/documentation/Configuration';
import DarkMode from 'components/documentation/DarkMode';
import Plugins from 'components/documentation/Plugins';
import Styling from 'components/documentation/Styling';
import DesignFile from 'components/documentation/DesignFile';
import Starter from 'components/pages/Starter';
import AnimatedIcons from 'components/doc-components/AnimatedIcons';
import DatePicker from 'components/doc-components/DatePicker';
import FontAwesome from 'components/doc-components/FontAwesome';
import Changelog from 'components/documentation/change-log/ChangeLog';
import Analytics from 'components/dashboards/analytics';
import Crm from 'components/dashboards/crm';
import Saas from 'components/dashboards/saas';
import Profile from 'components/pages/user/profile/Profile';
import Associations from 'components/pages/asscociations/Associations';
import Followers from 'components/app/social/followers/Followers';
import Notifications from 'components/app/social/notifications/Notifications';
import ActivityLog from 'components/app/social/activity-log/ActivityLog';
import Settings from 'components/pages/user/settings/Settings';
import Feed from 'components/app/social/feed/Feed';
import Placeholder from 'components/doc-components/Placeholder';
import Lightbox from 'components/doc-components/Lightbox';
import AdvanceTableExamples from 'components/doc-components/AdvanceTableExamples';
import ModalAuth from 'components/authentication/modal/ModalAuth';
import Calendar from 'components/app/calendar/Calendar';
import FaqAlt from 'components/pages/faq/faq-alt/FaqAlt';
import FaqBasic from 'components/pages/faq/faq-basic/FaqBasic';
import FaqAccordion from 'components/pages/faq/faq-accordion/FaqAccordion';
import PrivacyPolicy from 'components/pages/miscellaneous/privacy-policy/PrivacyPolicy';
import InvitePeople from 'components/pages/miscellaneous/invite-people/InvitePeople';
import PricingDefault from 'components/pages/pricing/pricing-default/PricingDefault';
import PricingAlt from 'components/pages/pricing/pricing-alt/PricingAlt';
import Invoice from 'components/app/e-commerce/Invoice';
import Billing from 'components/app/e-commerce/billing/Billing';
import Checkout from 'components/app/e-commerce/checkout/Checkout';
import ShoppingCart from 'components/app/e-commerce/cart/ShoppingCart';
import CustomersDetails from 'components/app/e-commerce/customers-details/CustomersDetails';
import OrderDetails from 'components/app/e-commerce/orders/order-details/OrderDetails';

import ProductDetails from 'components/app/e-commerce/product/product-details/ProductDetails';
import AddProduct from 'components/app/e-commerce/product/add-product/AddProduct';
import Orders from 'components/app/e-commerce/orders/order-list/Orders';
import Customers from 'components/app/e-commerce/customers/Customers';
import Courses from 'components/app/e-learning/course/Courses';
import CourseDetails from 'components/app/e-learning/course/course-details';
import CreateCourse from 'components/app/e-learning/course/create-a-course';
import TrainerProfile from 'components/app/e-learning/trainer-profile';
import StudentOverview from 'components/app/e-learning/student-overview';
import CreateEvent from 'components/app/events/create-an-event/CreateEvent';
import EventList from 'components/app/events/event-list/EventList';
import EventDetail from 'components/app/events/event-detail/EventDetail';
import EmailDetail from 'components/app/email/email-detail/EmailDetail';
import Compose from 'components/app/email/compose/Compose';
import Inbox from 'components/app/email/inbox/Inbox';
import Rating from 'components/doc-components/Rating';
import AdvanceSelect from 'components/doc-components/AdvanceSelect';
import Editor from 'components/doc-components/Editor';
import Chat from 'components/app/chat/Chat';
import Kanban from 'components/app/kanban/Kanban';
import DraggableExample from 'components/doc-components/DraggableExample';
import LineCharts from 'components/doc-components/charts-example/echarts/line-charts';
import BarCharts from 'components/doc-components/charts-example/echarts/bar-charts';
import CandlestickCharts from 'components/doc-components/charts-example/echarts/candlestick-charts';
import GeoMaps from 'components/doc-components/charts-example/echarts/geo-map';
import ScatterCharts from 'components/doc-components/charts-example/echarts/scatter-charts';
import PieCharts from 'components/doc-components/charts-example/echarts/pie-charts';
import RadarCharts from 'components/doc-components/charts-example/echarts/radar-charts/Index';
import HeatmapCharts from 'components/doc-components/charts-example/echarts/heatmap-chart';
import Chartjs from 'components/doc-components/charts-example/chartjs';
import D3js from 'components/doc-components/charts-example/d3';
import HowToUse from 'components/doc-components/charts-example/echarts/HowToUse';
import GoogleMapExample from 'components/doc-components/GoogleMapExample';
import LeafletMapExample from 'components/doc-components/LeafletMapExample';
import CookieNoticeExample from 'components/doc-components/CookieNoticeExample';
import Scrollbar from 'components/doc-components/Scrollbar';
import Scrollspy from 'components/doc-components/Scrollspy';
import ReactIcons from 'components/doc-components/ReactIcons';
import ReactPlayerExample from 'components/doc-components/ReactPlayerExample';
import EmojiMartExample from 'components/doc-components/EmojiMart';
import TreeviewExample from 'components/doc-components/TreeviewExample';
import Timeline from 'components/doc-components/Timeline';
import Widgets from 'widgets/Widgets';
import Ecommerce from 'components/dashboards/e-commerce';
import Lms from 'components/dashboards/lms';
import ProjectManagement from 'components/dashboards/project-management';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import SimpleLogin from 'components/authentication/simple/Login';
import SimpleLogout from 'components/authentication/simple/Logout';
import SimpleRegistration from 'components/authentication/simple/Registration';
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword';
import SimplePasswordReset from 'components/authentication/simple/PasswordReset';
import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail';
import SimpleLockScreen from 'components/authentication/simple/LockScreen';

import SplitLogin from 'components/authentication/split/Login';
import SplitLogout from 'components/authentication/split/Logout';
import SplitRegistration from 'components/authentication/split/Registration';
import SplitForgetPassword from 'components/authentication/split/ForgetPassword';
import SplitPasswordReset from 'components/authentication/split/PasswordReset';
import SplitConfirmMail from 'components/authentication/split/ConfirmMail';
import SplitLockScreen from 'components/authentication/split/LockScreen';

import Dashboard from 'components/dashboards/default';
import Faq from 'components/documentation/Faq';

import SupportDesk from 'components/dashboards/support-desk';
import TableView from 'components/app/support-desk/tickets-layout/TableView';
import CardView from 'components/app/support-desk/tickets-layout/CardView';
import Contacts from 'components/app/support-desk/contacts/Contacts';
import ContactDetails from 'components/app/support-desk/contact-details/ContactDetails';
import TicketsPreview from 'components/app/support-desk/tickets-preview/TicketsPreview';
import QuickLinks from 'components/app/support-desk/quick-links/QuickLinks';
import Reports from 'components/app/support-desk/reports/Reports';
import InputMaskExample from 'components/doc-components/InputMaskExample';
import RangeSlider from 'components/doc-components/RangeSlider';
//#endregion

//#region Final

//LOGIN
import Login from 'views/login/Login';
import Logout from 'views/login/Logout';
import Registration from 'views/login/Registration';
import ForgetPassword from 'views/login/ForgetPassword';
import ConfirmMail from 'views/login/ConfirmMail';
import PasswordReset from 'views/login/PasswordReset';
import LockScreen from 'views/login/LockScreen';

import WizardAuth from 'views/login/wizard/WizardAuth';
import Wizard from 'views/login/wizard/Wizard';

//#endregion

import ProtectedRoute from './ProtectedRoute';
import GuestRoute from './GuestRoute';
import { useAuth } from 'context/AuthContext';
import Products from 'views/posts/Products';

const FalconRoutes = () => {

  const { isLocked, isAuthenticated } = useAuth();

  return (
    <Routes>
      <Route path="landing" element={<Landing />} />
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      {/*- ------------- Authentication ---------------------------  */}
      
      <Route
        path="login"
        element={
          <GuestRoute isAuthenticated={isAuthenticated()}>
            <Login />
          </GuestRoute>
        }
        />
      <Route
        path="login/register"
        element={
          <GuestRoute isAuthenticated={isAuthenticated()}>
            <Registration />
          </GuestRoute>
        }
      />
      <Route
        path="logout"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Logout />
          </ProtectedRoute>
        }
      />
      <Route
        path="login/forgot-password"
        element={
          <GuestRoute isAuthenticated={isAuthenticated()}>
            <ForgetPassword />
          </GuestRoute>
        }
      />
      <Route
        path="login/reset-password"
        element={
          <GuestRoute isAuthenticated={isAuthenticated()}>
            <PasswordReset />
          </GuestRoute>
        }
      />
      <Route
        path="login/confirm-mail"
        element={
          <GuestRoute isAuthenticated={isAuthenticated()}>
            <ConfirmMail />
          </GuestRoute>
        }
      />
      <Route
        path="/lock-screen"
        element={
          <ProtectedRoute isAuthenticated={isLocked()}>
            <LockScreen />
          </ProtectedRoute>
        }
      />

      {//isAuthenticated() &&
        (//(hasIntegration('MERCADO_LIBRE'))?
        <Route element={<MainLayout />}>
          <Route path="/" element={
              <ProtectedRoute isAuthenticated={isAuthenticated()}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/posts"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated()}>
                <Products />
              </ProtectedRoute>
            }
          />

          <Route
            path="/posts/details"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated()}>
                <ProductDetails />
              </ProtectedRoute>}
          />
          <Route path="/posts/add" element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <AddProduct />
            </ProtectedRoute>} />
          <Route
            path="/posts/details/:postId"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated()}>
                <ProductDetails />
              </ProtectedRoute>}
          />
        </Route>
        //:
      )}
      <Route element={<WizardAuth />}>
        <Route
          path="/wizard"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <Wizard validation={true} />
            </ProtectedRoute>
          }
        />
      </Route>

      {/*- ------------- simple ---------------------------  */}
      <Route element={<AuthSimpleLayout />}>
        <Route path="authentication/simple/login" element={<SimpleLogin />} />
        <Route
          path="authentication/simple/register"
          element={<SimpleRegistration />}
        />
        <Route path="authentication/simple/logout" element={<SimpleLogout />} />
        <Route
          path="authentication/simple/forgot-password"
          element={<SimpleForgetPassword />}
        />
        <Route
          path="authentication/simple/reset-password"
          element={<SimplePasswordReset />}
        />
        <Route
          path="authentication/simple/confirm-mail"
          element={<SimpleConfirmMail />}
        />
        <Route
          path="authentication/simple/lock-screen"
          element={<SimpleLockScreen />}
        />
      </Route>

      {/*- ------------- Split ---------------------------  */}

      <Route path="authentication/split/login" element={<SplitLogin />} />
      <Route path="authentication/split/logout" element={<SplitLogout />} />
      <Route
        path="authentication/split/register"
        element={<SplitRegistration />}
      />
      <Route
        path="authentication/split/forgot-password"
        element={<SplitForgetPassword />}
      />
      <Route
        path="authentication/split/reset-password"
        element={<SplitPasswordReset />}
      />
      <Route
        path="authentication/split/confirm-mail"
        element={<SplitConfirmMail />}
      />
      <Route
        path="authentication/split/lock-screen"
        element={<SplitLockScreen />}
      />

      <Route element={<WizardAuth />}>
        <Route
          path="authentication/wizard"
          element={<Wizard validation={true} />}
        />
      </Route>

      {/* //--- MainLayout Starts  */}

      <Route element={<MainLayout />}>
        <Route path="dashboard/analytics" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Analytics />
          </ProtectedRoute>} />
        <Route path="dashboard/crm" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Crm />
          </ProtectedRoute>} />
        <Route path="dashboard/saas" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Saas />
          </ProtectedRoute>} />
        <Route path="dashboard/e-commerce" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Ecommerce />
          </ProtectedRoute>} />
        <Route path="dashboard/lms" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Lms />
          </ProtectedRoute>} />
        <Route
          path="dashboard/project-management"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <ProjectManagement />
            </ProtectedRoute>}
        />
        <Route path="dashboard/support-desk" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <SupportDesk />
          </ProtectedRoute>} />
        {/* E Commerce */}
        <Route
          path="e-commerce/orders/order-details"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <OrderDetails />
            </ProtectedRoute>}
        />
        <Route path="e-commerce/orders/order-list" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Orders />
          </ProtectedRoute>} />
        <Route path="e-commerce/invoice" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Invoice />
          </ProtectedRoute>} />
        <Route path="e-commerce/billing" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Billing />
          </ProtectedRoute>} />
        <Route path="e-commerce/checkout" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Checkout />
          </ProtectedRoute>} />
        <Route path="e-commerce/shopping-cart" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <ShoppingCart />
          </ProtectedRoute>} />
        <Route path="e-commerce/customers" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Customers />
          </ProtectedRoute>} />
        <Route
          path="e-commerce/customer-details"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <CustomersDetails />
            </ProtectedRoute>}
        />

        <Route
          path="e-commerce/product/product-details"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <ProductDetails />
            </ProtectedRoute>}
        />
        <Route path="e-commerce/product/add-product" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <AddProduct />
          </ProtectedRoute>} />
        <Route
          path="e-commerce/product/product-details/:productId"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <ProductDetails />
            </ProtectedRoute>}
        />

        <Route
          path="e-commerce/product/:productLayout"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <Products />
            </ProtectedRoute>}
        />

        <Route path="e-commerce/invoice" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Invoice />
          </ProtectedRoute>} />

        {/* E Learning */}
        <Route path="e-learning/course/:courseLayout" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Courses />
          </ProtectedRoute>} />
        <Route
          path="e-learning/course/course-details"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <CourseDetails />
            </ProtectedRoute>}
        />
        <Route
          path="e-learning/course/course-details/:courseId"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <CourseDetails />
            </ProtectedRoute>}
        />
        <Route
          path="e-learning/course/create-a-course"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <CreateCourse />
            </ProtectedRoute>}
        />
        <Route path="e-learning/trainer-profile" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <TrainerProfile />
          </ProtectedRoute>} />
        <Route
          path="e-learning/student-overview"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <StudentOverview />
            </ProtectedRoute>}
        />

        {/*icons*/}
        <Route path="icons/font-awesome" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <FontAwesome />
          </ProtectedRoute>} />
        <Route path="icons/react-icons" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <ReactIcons />
          </ProtectedRoute>} />

        {/* maps */}
        <Route path="maps/google-map" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <GoogleMapExample />
          </ProtectedRoute>} />
        <Route path="maps/leaflet-map" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <LeafletMapExample />
          </ProtectedRoute>} />

        {/*App*/}
        <Route path="app/calendar" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Calendar />
          </ProtectedRoute>} />
        <Route path="app/chat" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Chat />
          </ProtectedRoute>} />
        <Route path="app/kanban" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Kanban />
          </ProtectedRoute>} />
        <Route path="social/feed" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Feed />
          </ProtectedRoute>} />
        <Route path="social/activity-log" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <ActivityLog />
          </ProtectedRoute>} />
        <Route path="social/notifications" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Notifications />
          </ProtectedRoute>} />
        <Route path="social/followers" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Followers />
          </ProtectedRoute>} />
        <Route path="events/event-detail" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <EventDetail />
          </ProtectedRoute>} />
        <Route path="events/create-an-event" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <CreateEvent />
          </ProtectedRoute>} />
        <Route path="events/event-list" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <EventList />
          </ProtectedRoute>} />

        {/* Email */}
        <Route path="email/email-detail" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <EmailDetail />
          </ProtectedRoute>} />
        <Route path="email/inbox" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Inbox />
          </ProtectedRoute>} />
        <Route path="email/compose" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Compose />
          </ProtectedRoute>} />

        {/* support desk */}
        <Route path="/support-desk/table-view" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <TableView />
          </ProtectedRoute>} />
        <Route path="/support-desk/card-view" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <CardView />
          </ProtectedRoute>} />
        <Route path="/support-desk/contacts" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Contacts />
          </ProtectedRoute>} />
        <Route
          path="/support-desk/contact-details"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <ContactDetails />
            </ProtectedRoute>}
        />
        <Route
          path="/support-desk/tickets-preview"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <TicketsPreview />
            </ProtectedRoute>}
        />
        <Route path="/support-desk/quick-links" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <QuickLinks />
          </ProtectedRoute>} />
        <Route path="/support-desk/reports" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Reports />
          </ProtectedRoute>} />

        {/*Pages*/}
        <Route path="pages/starter" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Starter />
          </ProtectedRoute>} />
        <Route path="user/profile" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Profile />
          </ProtectedRoute>} />
        <Route path="user/settings" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Settings />
          </ProtectedRoute>} />
        <Route path="miscellaneous/associations" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Associations />
          </ProtectedRoute>} />
        <Route path="faq/faq-alt" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <FaqAlt />
          </ProtectedRoute>} />
        <Route path="faq/faq-basic" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <FaqBasic />
          </ProtectedRoute>} />
        <Route path="faq/faq-accordion" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <FaqAccordion />
          </ProtectedRoute>} />
        <Route path="pricing/pricing-default" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <PricingDefault />
          </ProtectedRoute>} />
        <Route path="pricing/pricing-alt" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <PricingAlt />
          </ProtectedRoute>} />
        <Route
          path="miscellaneous/privacy-policy"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <PrivacyPolicy />
            </ProtectedRoute>}
        />
        <Route path="miscellaneous/invite-people" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <InvitePeople />
          </ProtectedRoute>} />
        {/* charts-example */}

        <Route path="charts/chartjs" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Chartjs />
          </ProtectedRoute>} />
        <Route path="charts/d3js" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <D3js />
          </ProtectedRoute>} />
        <Route path="charts/echarts/line-charts" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <LineCharts />
          </ProtectedRoute>} />
        <Route path="charts/echarts/bar-charts" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <BarCharts />
          </ProtectedRoute>} />
        <Route
          path="charts/echarts/candlestick-charts"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <CandlestickCharts />
            </ProtectedRoute>}
        />
        <Route path="charts/echarts/geo-map" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <GeoMaps />
          </ProtectedRoute>} />
        <Route
          path="charts/echarts/scatter-charts"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <ScatterCharts />
            </ProtectedRoute>}
        />
        <Route path="charts/echarts/pie-charts" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <PieCharts />
          </ProtectedRoute>} />
        <Route path="charts/echarts/radar-charts" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <RadarCharts />
          </ProtectedRoute>} />
        <Route
          path="charts/echarts/heatmap-charts"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <HeatmapCharts />
            </ProtectedRoute>}
        />
        <Route path="charts/echarts/how-to-use" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <HowToUse />
          </ProtectedRoute>} />

        {/*Components*/}
        <Route path="components/alerts" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Alerts />
          </ProtectedRoute>} />
        <Route path="components/accordion" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Accordion />
          </ProtectedRoute>} />
        <Route path="components/animated-icons" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <AnimatedIcons />
          </ProtectedRoute>} />
        <Route path="components/badges" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Badges />
          </ProtectedRoute>} />
        <Route path="components/breadcrumb" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Breadcrumbs />
          </ProtectedRoute>} />
        <Route path="components/buttons" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Buttons />
          </ProtectedRoute>} />
        <Route path="components/calendar" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <CalendarExample />
          </ProtectedRoute>} />
        <Route path="components/cards" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Cards />
          </ProtectedRoute>} />
        <Route path="components/dropdowns" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Dropdowns />
          </ProtectedRoute>} />
        <Route path="components/list-group" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <ListGroups />
          </ProtectedRoute>} />
        <Route path="components/modals" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Modals />
          </ProtectedRoute>} />
        <Route path="components/offcanvas" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Offcanvas />
          </ProtectedRoute>} />
        <Route path="components/pagination" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Pagination />
          </ProtectedRoute>} />
        <Route path="components/progress-bar" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <BasicProgressBar />
          </ProtectedRoute>} />
        <Route path="components/placeholder" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Placeholder />
          </ProtectedRoute>} />
        <Route path="components/spinners" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Spinners />
          </ProtectedRoute>} />
        <Route path="components/toasts" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Toasts />
          </ProtectedRoute>} />
        <Route path="components/pictures/avatar" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Avatar />
          </ProtectedRoute>} />
        <Route path="components/pictures/images" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Image />
          </ProtectedRoute>} />
        <Route path="components/pictures/figures" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Figures />
          </ProtectedRoute>} />
        <Route path="components/pictures/hoverbox" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Hoverbox />
          </ProtectedRoute>} />
        <Route path="components/pictures/lightbox" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Lightbox />
          </ProtectedRoute>} />
        <Route path="components/tooltips" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Tooltips />
          </ProtectedRoute>} />
        <Route path="components/popovers" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Popovers />
          </ProtectedRoute>} />
        <Route path="components/draggable" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <DraggableExample />
          </ProtectedRoute>} />
        <Route path="components/scrollspy" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Scrollspy />
          </ProtectedRoute>} />
        <Route path="components/timeline" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Timeline />
          </ProtectedRoute>} />
        <Route path="components/treeview" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <TreeviewExample />
          </ProtectedRoute>} />
        <Route
          path="components/carousel/bootstrap"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <BootstrapCarousel />
            </ProtectedRoute>}
        />
        <Route path="components/carousel/slick" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <SlickCarousel />
          </ProtectedRoute>} />
        <Route path="components/navs-and-tabs/navs" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Navs />
          </ProtectedRoute>} />
        <Route path="tables/basic-tables" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Tables />
          </ProtectedRoute>} />
        <Route
          path="tables/advance-tables"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <AdvanceTableExamples />
            </ProtectedRoute>}
        />
        <Route path="forms/basic/form-control" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <FormControl />
          </ProtectedRoute>} />
        <Route path="forms/basic/input-group" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <InputGroup />
          </ProtectedRoute>} />
        <Route path="forms/basic/select" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Select />
          </ProtectedRoute>} />
        <Route path="forms/basic/checks" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Checks />
          </ProtectedRoute>} />
        <Route path="forms/basic/range" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Range />
          </ProtectedRoute>} />
        <Route path="forms/basic/layout" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <FormLayout />
          </ProtectedRoute>} />
        <Route path="forms/advance/date-picker" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <DatePicker />
          </ProtectedRoute>} />
        <Route path="forms/advance/editor" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Editor />
          </ProtectedRoute>} />
        <Route
          path="forms/advance/emoji-button"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <EmojiMartExample />
            </ProtectedRoute>}
        />
        <Route
          path="forms/advance/advance-select"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <AdvanceSelect />
            </ProtectedRoute>}
        />
        <Route path="forms/advance/input-mask" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <InputMaskExample />
          </ProtectedRoute>} />
        <Route path="forms/advance/range-slider" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <RangeSlider />
          </ProtectedRoute>} />
        <Route path="forms/advance/file-uploader" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <FileUploader />
          </ProtectedRoute>} />
        <Route path="forms/advance/rating" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Rating />
          </ProtectedRoute>} />
        <Route path="forms/floating-labels" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <FloatingLabels />
          </ProtectedRoute>} />
        <Route path="forms/validation" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <FormValidation />
          </ProtectedRoute>} />
        <Route path="forms/wizard" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <WizardForms />
          </ProtectedRoute>} />
        <Route path="components/navs-and-tabs/navbar" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Navbars />
          </ProtectedRoute>} />
        <Route path="components/navs-and-tabs/tabs" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Tabs />
          </ProtectedRoute>} />
        <Route path="components/collapse" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Collapse />
          </ProtectedRoute>} />
        <Route
          path="components/cookie-notice"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <CookieNoticeExample />
            </ProtectedRoute>}
        />
        <Route path="components/countup" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <CountUp />
          </ProtectedRoute>} />
        <Route path="components/videos/embed" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Embed />
          </ProtectedRoute>} />
        <Route
          path="components/videos/react-player"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <ReactPlayerExample />
            </ProtectedRoute>}
        />
        <Route path="components/background" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Background />
          </ProtectedRoute>} />
        <Route path="components/search" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Search />
          </ProtectedRoute>} />
        <Route path="components/typed-text" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <TypedText />
          </ProtectedRoute>} />
        <Route
          path="components/navs-and-tabs/vertical-navbar"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <VerticalNavbar />
            </ProtectedRoute>}
        />
        <Route
          path="components/navs-and-tabs/top-navbar"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <NavBarTop />
            </ProtectedRoute>}
        />
        <Route
          path="components/navs-and-tabs/double-top-navbar"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <NavbarDoubleTop />
            </ProtectedRoute>}
        />
        <Route
          path="components/navs-and-tabs/combo-navbar"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <ComboNavbar />
            </ProtectedRoute>}
        />

        {/*Utilities*/}
        <Route path="utilities/borders" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Borders />
          </ProtectedRoute>} />
        <Route path="utilities/colors" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Colors />
          </ProtectedRoute>} />
        <Route path="utilities/colored-links" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <ColoredLinks />
          </ProtectedRoute>} />
        <Route path="utilities/display" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Display />
          </ProtectedRoute>} />
        <Route path="utilities/visibility" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Visibility />
          </ProtectedRoute>} />
        <Route path="utilities/stretched-link" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <StretchedLink />
          </ProtectedRoute>} />
        <Route path="utilities/stretched-link" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <StretchedLink />
          </ProtectedRoute>} />
        <Route path="utilities/float" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Float />
          </ProtectedRoute>} />
        <Route path="utilities/position" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Position />
          </ProtectedRoute>} />
        <Route path="utilities/spacing" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Spacing />
          </ProtectedRoute>} />
        <Route path="utilities/sizing" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Sizing />
          </ProtectedRoute>} />
        <Route path="utilities/text-truncation" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <TextTruncation />
          </ProtectedRoute>} />
        <Route path="utilities/typography" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Typography />
          </ProtectedRoute>} />
        <Route path="utilities/vertical-align" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <VerticalAlign />
          </ProtectedRoute>} />
        <Route path="utilities/flex" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Flex />
          </ProtectedRoute>} />
        <Route path="utilities/grid" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Grid />
          </ProtectedRoute>} />
        <Route path="utilities/scroll-bar" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Scrollbar />
          </ProtectedRoute>} />

        <Route path="widgets" element={
          <ProtectedRoute isAuthenticated={isAuthenticated()}>
            <Widgets />
          </ProtectedRoute>} />

        {/*Documentation*/}
        <Route
          path="documentation/getting-started"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated()}>
              <GettingStarted />
            </ProtectedRoute>}
        />
        <Route path="documentation/configuration" element={<Configuration />} />
        <Route path="documentation/styling" element={<Styling />} />
        <Route path="documentation/dark-mode" element={<DarkMode />} />
        <Route path="documentation/plugin" element={<Plugins />} />
        <Route path="documentation/faq" element={<Faq />} />
        <Route path="documentation/design-file" element={<DesignFile />} />
        <Route path="changelog" element={<Changelog />} />
        <Route path="authentication-modal" element={<ModalAuth />} />
      </Route>

      {/* //--- MainLayout end  */}

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
